<template>
  <div>
    <section class="about--sec about--ct" :id="slug">
      <h2>{{ data.title }}</h2>
      <div class="about--ct--intro">
        <p>
          {{ data.description }}
        </p>
      </div>
      <div class="about--ct--body">
        <div class="row" v-for="(row, index) in data.collabCredit" :key="index">
          <div class="col-12 col-md-6">
            <div class="about--ct--col">
              <h3>{{ $t("about.about_collaborators") }}:</h3>
              <div
                class="about--ct--item"
                v-for="(collab, _i) in row.collaborators"
                :key="_i"
              >
                <h4>{{ collab.name }}</h4>
                <p>
                  {{ collab.description }}
                </p>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="about--ct--col">
              <h3>{{ $t("about.about_credit") }}:</h3>
              <div
                class="about--ct--item"
                v-for="(credit, __i) in row.credit"
                :key="__i"
              >
                <h4>{{ credit.name }}</h4>
                <p>
                  {{ credit.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "acknowledgment",
  data() {
    return {};
  },
  beforeMount() {},
  props: {
    data: Object,
    slug: String
  },

  methods: {}
};
</script>

<style lang="scss" scoped>
.about {
  &--sec {
    margin-bottom: 40px;
    @include respond-to(screen-md) {
      margin-bottom: 80px;
    }
    h2 {
      font-size: $fs-r-xl;
      font-weight: 400;
      margin-bottom: 20px;
      @include respond-to(screen-md) {
        font-size: $fs-xxl;
      }
    }
    p {
      margin-bottom: 10px;
    }
    h3 {
      font-size: $fs-r-xl;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 10px;
    }
  }
  &--ct {
    &--intro {
      width: 100%;
      @include respond-to(screen-sm) {
        width: 50%;
      }
      p {
        margin-bottom: 40px;
      }
    }
    &--col {
      padding-right: 0px;
      margin-bottom: 30px;
      @include respond-to(screen-sm) {
        padding-right: 60px;
      }
    }
    &--item {
      margin-bottom: 15px;
      @include respond-to(screen-sm) {
        margin-bottom: 30px;
      }
    }
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
      @include respond-to(screen-sm) {
        font-size: 24px;
      }
    }
    h4 {
      font-size: 18px;
      font-weight: 400;
      @include respond-to(screen-sm) {
        font-size: 20px;
      }
    }
  }
}
</style>
